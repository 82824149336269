import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../hooks/useDebounce';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { PickList, PickListInfo } from '../../models/Picklist';
import PickListService from '../../services/PickListService';
import LanguageUtils from '../../utils/LanguageUtils';
import { nextTick } from '../../utils/ReactUtils';
import { SearchInput } from '../shared/form-control/SearchInput';
import { ChevronIcon, ChevronType } from '../shared/icon/ChevronIcon';
import InfoIcon from '../shared/icon/InfoIcon';
import Loader from '../shared/Loader';
import { Heading, HeadingSize } from '../shared/text/Heading';
import OrgPickListItems from './OrgPickListItems';

const OrgPickLists: FC = () => {
  const [picklists, setPicklists] = useState<PickListInfo[]>([]);
  const [selectedPicklist, setSelectedPicklist] = useState<PickList | null>(null);
  const [searchPhrase, setSearchPhrase] = useState('');
  const debouncedSearchPhrase = useDebounce(searchPhrase, 500);
  const [pageNumber, setPageNumber] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation('organisation');

  const getPicklists = useCallback(() => {
    const scrollTop = window.scrollY;
    setLoading(true);

    PickListService.getAll({
      pageSize: 10,
      pageNumber,
      searchTerm: debouncedSearchPhrase,
      includeArchived: false,
      isSystem: false,
    }).then((res) => {
      setPicklists((prev) => {
        return [...(pageNumber > 1 ? prev : []), ...res.data];
      });
      setShowLoadMore(res.hasNextPage || false);
      setLoading(false);

      nextTick(() => {
        window.scrollTo({ top: scrollTop });
      });
    });
  }, [debouncedSearchPhrase, pageNumber]);

  useEffect(() => {
    getPicklists();
  }, [getPicklists]);

  const onSearchPhraseChange = useCallback((value: string) => {
    setPageNumber(1);
    setSearchPhrase(value);
  }, []);

  const getPicklist = (pickListId: string) => {
    PickListService.getPickList(pickListId, true).then((res) => {
      setSelectedPicklist(res.data);
    });
  };

  const onLoadMore = useCallback(() => {
    setPageNumber((prev) => prev + 1);
  }, []);

  const [lastElementRef] = useInfiniteScroll(showLoadMore ? onLoadMore : null, loading);

  return (
    <>
      {selectedPicklist && (
        <OrgPickListItems
          picklist={selectedPicklist}
          onClose={() => setSelectedPicklist(null)}
          onItemsChange={() => getPicklist(selectedPicklist.id)}
        />
      )}
      {!selectedPicklist && (
        <div className="h-full pt-6">
          <div>
            <div className="flex justify-between">
              <Heading size={HeadingSize.H3}>{t('tabs.consultant-picklists')}</Heading>
              <div className="w-80">
                <SearchInput
                  data-cy="picklist-search"
                  placeholder="Search"
                  value={searchPhrase}
                  onChange={(e) => onSearchPhraseChange(e.target.value)}
                />
              </div>
            </div>
            <div className="pt-2 text-black">{t('picklists.dynamic-description')}</div>
          </div>
          <div className="-mx-6 p-8">
            <div className="relative">
              {picklists.map((picklist, i) => {
                const isLast = picklists.length === i + 1;
                return (
                  <div
                    data-cy={`picklist-${picklist.id}`}
                    key={picklist.id}
                    ref={isLast ? lastElementRef : undefined}
                    className="border-gray-5 my-2 flex cursor-pointer items-center justify-between gap-4 border-2 bg-white px-6 py-4"
                    onClick={() => getPicklist(picklist.id)}
                  >
                    <div className="w-1/4 font-medium text-black">{LanguageUtils.getTranslation('name', picklist.translations)}</div>
                    <div className="flex flex-shrink-0 items-center gap-4">
                      <ChevronIcon type={ChevronType.RIGHT} className="h-6 w-6" />
                    </div>
                  </div>
                );
              })}
              {loading && (
                <div className="flex flex-col items-center py-6">
                  <Loader size={16} centered={false} />
                </div>
              )}
              {!picklists.length && (
                <div data-cy="picklist-empty" className="flex h-full justify-center ">
                  <div className="my-32 text-center">
                    <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
                    <div className="text-dpm-20 mt-8">{t('picklists.empty')}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrgPickLists;
